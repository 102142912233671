var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.handleProjectVisibility,
    once: true,
    intersection: { threshold: 0.3 },
  }),expression:"{\n    callback: handleProjectVisibility,\n    once: true,\n    intersection: { threshold: 0.3 },\n  }"}],staticClass:"project-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.projectVisible),expression:"projectVisible"}],staticClass:"project-item",class:[
      'project-grid-container-' + _vm.getOrientation,
      'slide-in-' + _vm.getOrientation,
    ]},[_c('div',{staticClass:"project-text-container"},[_c('h2',[_vm._v(_vm._s(_vm.project.name))]),_c('p',[_vm._v(_vm._s(_vm.project.description))])]),_c('div',{staticClass:"project-buttons-container flex-container-row"},[(_vm.project.githubUrl)?_c('Button',{attrs:{"button":_vm.githubButton},on:{"buttonClicked":function($event){return _vm.handleOpenProjectGithub()}}}):_vm._e(),(_vm.project.liveUrl)?_c('Button',{attrs:{"button":_vm.liveButton},on:{"buttonClicked":function($event){return _vm.handleOpenProjectLive()}}}):_vm._e(),(_vm.project.productPage)?_c('Button',{attrs:{"button":_vm.productWebsiteButton},on:{"buttonClicked":function($event){return _vm.handleOpenProductWebsite()}}}):_vm._e()],1),_c('ul',{staticClass:"project-technologies-container flex-container-row"},_vm._l((_vm.project.technologiesList),function(technology){return _c('TechnologyIcon',{key:technology.id,attrs:{"technology":technology}})}),1),_c('a',{attrs:{"href":_vm.project.liveUrl}},[_c('img',{attrs:{"id":_vm.mockupId,"src":require(`@/assets/images/project_mockups/${_vm.project.mockupFileName}`)}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }